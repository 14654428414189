// import { css } from 'astroturf';
// export default css`

// accordion
.root {
  display: inline-block;
  width: 100%;
}

.headContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.label {
  // font-family: TT Hoves Regular, sans-serif;
  font-family: var(--main-regular-font);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;
}
// label__right
.labelRight {
  padding-left: 0;
}

.toggle {
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.4s;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent center;

  /* background-image: url('../../icons/AccordionArrow.svg'); */
  background-image: url('./AccordionArrow.svg');
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
// active/opened
.toggleAfter {
  transform: rotate(0deg);
}
// .accordion__after {
//   /* background-image: url('../../icons/AccordionArrow.svg'); */
//   background-image: url('./AccordionArrow.svg');
//   background-repeat: no-repeat;
//   transform: rotate(180deg);
// }
// .active__after {
//   /* background-image: url('../../icons/AccordionArrow.svg'); */
//   background-image: url('./AccordionArrow.svg');
//   background-repeat: no-repeat;
// }

// .accordion_panel {
//   padding: 0 18px;
//   background: transparent;
//   display: none;
//   overflow: hidden;
// }

// .accordion_panel__active {
.bodyContainer {
  // может быть убрать отступы!?
  // padding: 0 10px;
  background: transparent;
  // display: flex;
}
