.selectContainer {
  display: flex;
  align-items: center;
  position: relative;
  // width: 200px;
  width: 100%;
}

.arrowDownIconContainer {
  position: absolute;
  display: flex;
  right: 10px;
  pointer-events: none;
}

.selectContent {
  // width: 200px;
  width: 100%;
  height: 40px;
  padding: 5px 30px 5px 10px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  appearance: none;
  background-origin: content-box;
}
