.ButtonWithUnderlay {
  height: 39px;
  border-radius: 3px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ButtonWithUnderlay__Color_default {
  background: #6ac3fa;
}

.ButtonWithUnderlay__Color_default:hover {
  background: #68b9f0;
}

.ButtonWithUnderlay__Color_default:active {
  background: #65b1e6;
}

.ButtonWithUnderlay__Color_light {
  background: #f2f2f2;
}

.ButtonWithUnderlay__Color_light:hover {
  background: #c9c9c9;
}

.ButtonWithUnderlay__Color_white {
  background: #ffffff;
}

.ButtonWithUnderlay__Color_white:hover {
  background: #e6e6e6;
}

.ButtonWithUnderlay__Color_white:active {
  background: #d7d7d7;
}

.ButtonWithUnderlay__Color_light:active {
  background: #aaaaaa;
}

.ButtonWithUnderlay__Font {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}

.ButtonWithUnderlay__Font_color {
  color: #ffffff;
}

.ButtonWithUnderlay__Font_color_light {
  color: #6ac3fa;
}
