.button_tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button_tabs__div_button {
  cursor: pointer;
  color: #5dbdf9;
  user-select: none;
  display: flex;
  flex-direction: column;
}

.button_tabs__div_button__padding_top {
  padding: 10px 0 0 0;
}

.button_tabs__div_button__active {
  cursor: default;
  border-bottom: 2px solid #5dbdf9;
  color: #ffffff;
}

.button_tabs__span {
  padding: 8px 0;
}
