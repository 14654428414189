.root {
  display: flex;
  justify-content: flex-end;
  margin: 0 16px;
}

.wrapper {
  display: flex;
}

.pagingControls {
  display: flex;
}

.pagingCaption {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin: 0 8px;
}
