.modalBox:hover {
  background-color: #282c34;
}

.modalBox {
  animation-name: animatetop;
  animation-duration: 1s;
  /* -webkit-animation-name: animatetop; */
  /* -webkit-animation-duration: 1s; */
  position: absolute;
}

@-webkit-keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
