.container {
  position: relative;
  display: inline-block;

  // margin-bottom: 200px;
  // margin-right: 100px;
}
.button {
  padding: 0;
  // width: 50px;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  // font-size: 40px;
  font-size: 1em;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 3px;

  height: 45px;

  &:hover,
  &.active {
    background-color: #ececec;
  }
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 220px; // width: 300px;
  z-index: 20;
  border: 1px solid rgba(0, 0, 0, 0.04);
  // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  box-shadow: 0 -8px 24px 2px rgba(0, 0, 0, 0.14);
  background: white;
  // new
  margin: 8px 0;
  border-radius: 3px;

  // --- dropup ---
  // top: auto;
  // bottom: 100%;
  // --- and right ---
  // left: auto;
  // right: 0;
  //

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 8px 12px;
    color: var(--text-common-color);
    user-select: none;
  }

  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
}

.header {
  // display: inline-block;
  cursor: default;
  user-select: none;
  padding: 4px 12px 0;
  color: var(--text-secondary-color);
}

.divider {
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  height: 0;
  margin: 0.5em 0;
}

// modifiers
.container.dropup .dropdown {
  top: auto;
  bottom: 100%;
}
.container.pullRight .dropdown {
  left: auto;
  right: 0;
}
// end
