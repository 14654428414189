.range_input__track {
  height: 2px;
  background-color: rgb(196, 196, 196);
  position: relative;
}

.range_input__thumb {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: var(--main-secondary-color);

  &:hover {
    background-color: var(--main-secondary-hover-color);
  }
  &:active {
    background-color: var(--main-secondary-active-color);
  }
}
.range_input__thumb_label {
  position: absolute;
  top: -20px;
  font-family: TT Hoves Regular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 21px;
  display: flex;
  justify-content: center;
  color: var(--text-primary-color);
}
.range_input__min_max_label__container {
  display: flex;
  justify-content: space-between;
}
.range_input__min_max_label {
  font-family: TT Hoves Regular, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--text-primary-color);
}
