.DayPickerInput input {
  border: none;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  height: 31px;
  text-align: center;
  width: 100%;
  padding: 0 30px 0 10px;
  outline: 0;
  color: #a8b1c2;
  box-sizing: border-box;
}

.DayPickerInput {
  position: relative;
  display: block;
  width: 100%;
}

.DayPickerInput input::placeholder {
  text-align: left;
  padding-left: 8px;
}

.DayPickerInput input:focus::placeholder {
  display: block;
}

.date_input_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  border: #14306a solid 2px;
}

.date_input_container_error {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  border: #ff0008 solid 2px;
  border-radius: 5px;
}

.datePickerIcon {
  position: absolute;
  display: flex;
  padding-right: 10px;
  pointer-events: none;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: #ff0008;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  margin-bottom: 7px;
  transition: 0.8s;
  opacity: 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50%;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ff0008 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
