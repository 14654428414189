.table {
  border-collapse: collapse;
  // width: 100%;
  font-size: 0.875rem; // 14px;
}

.table thead {
  border-bottom: 2px solid rgba(20, 48, 106, 0.1); // #14306A (без alfa синий, из дизайна лол)
  // border-bottom: 1px solid rgba(38, 21, 13, 0.1)
}

.table tr {
  border-bottom: 1px solid rgba(20, 48, 106, 0.1);

  &:last-child {
    border: none;
  }
}

.table th {
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;

  font-weight: normal;
  color: #8997b4;
}

.table td {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;

  font-weight: normal;
  color: #000;
}
