/* ACTUAL VARIABLES FILE (don't edit variables.json)!!!

css variables https://developer.mozilla.org/ru/docs/Web/CSS/Using_CSS_custom_properties
theme with react hooks context https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

keyworks for theme see https://material-ui.com/customization/default-theme/
  https://amplifr.com/uikit/#Colors:ru
  https://slack.engineering/building-dark-mode-on-desktop-811508b5d15f#aaec
*/

/* 'TTHovesRegular'; 'TT Hoves Regular'; MyFont */
@font-face {
  font-family: TT Hoves Regular;
  src: url('../fonts/TT Hoves Regular.otf') format('opentype');
}
@font-face {
  font-family: TT Hoves DemiBold;
  src: url('../fonts/TT Hoves DemiBold.otf') format('opentype');
}

body {
  font-family: var(--main-regular-font);
}

:root {
  --main-primary-color: #14306a;
  /* --main-color */
  --main-secondary-color: #5ab8f1;
  --main-secondary-hover-color: #4BAEEB;
  --main-secondary-active-color: #359EDE;
  --main-common-color: #8997B4;
  --main-success-color: #5dd670;
  --main-dangerous-color: #ff5f5f; /* #FF4A4A; accent?, error- */
  --main-check-box-mark-color: rgba(106, 195, 250, 0.4);
  /* fonts */
  --main-font-family-tmp: Verdana, Arial, sans-serif;
  --main-regular-font: 'TT Hoves Regular', sans-serif;
  --main-demibold-font: 'TT Hoves DemiBold', sans-serif;
  /* font colors */
  --text-primary-color: #fff;
  --text-secondary-color: #8997b4;
  --text-accent-color: #5ab8f1; /* links */
  --text-common-color: #000;
  /* background colors */
  --bg-primary-color: #14306a;
  --bg-secondary-color: #fff; /* alt-primary */
  /* icon colors */
  --icon-primary-color: #5ab8f1;
}
