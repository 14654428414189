.headerCell {
  cursor: default;
  user-select: none;
  // cursor: pointer;

  display: flex;
  /* justify-content: center; */
  align-items: center;

  // &:hover > .sortIcon {
  //   opacity: 1;
  // }
}

.sortableHeaderCell {
  cursor: pointer;

  &:hover > .sortIcon {
    opacity: 1;
  }
}

.sortedHeaderCell {
  & > .sortIcon {
    opacity: 1;
  }

  &:global(.sort-direction--desc svg) {
    transform: rotate(0deg);
  }

  &:global(.sort-direction--asc svg) {
    transform: rotate(180deg);
  }
}

.sortIcon {
  // display: inline-block;
  // vertical-align: middle;
  font-size: 0;
  padding: 0px 2px;
  /* color: rgba(0, 0, 0, 0.87); */
  opacity: 0;
  // transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  svg {
    color: rgba(0, 0, 0, 0.54);
    fill: currentColor;
    // transform: rotate(180deg);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
