
  .root {
    display: inline-block;
    width: 100%;
  }

  .input_password__container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .input_password {
    padding-left: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    font-size: 12px;
    padding-right: 40px;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:focus::placeholder {
      color: transparent;
    }
  }

  .input_password__button {
    border: 0;
    background: transparent;
    cursor: pointer;
    outline: 0;
    display: flex;
  }

  .input_password__icon {
    position: absolute;
    padding-right: 8px;
  }

  .error_input {
    border-color: #ff4a4a;
  }

  .error_msg {
    color: #ff4a4a;
    margin: 0;
    padding: 2px 4px;
    line-height: 14px;
    font-size: 12px;
  }
