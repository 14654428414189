// .root
.ButtonWithUnderlay {
  height: 39px;
  border-radius: 3px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;

  border-width: 0px; // defaults problem: ff desktop - 2px, 1.6px - ff notebook
}

.ButtonWithUnderlay__Color_default {
  background: #6ac3fa;
  &:hover {
    background: #68b9f0;
  }
  &:active {
    background: #65b1e6;
  }
}

.ButtonWithUnderlay__Color_light {
  background: #f2f2f2;
  &:hover {
    background: #c9c9c9;
  }
  &:active {
    background: #bababa;
  }
}

.ButtonWithUnderlay__Color_white {
  background: #ffffff;
  &:hover {
    background: #e6e6e6;
  }
  &:active {
    background: #d7d7d7;
  }
}

.ButtonWithUnderlay__Color_transparent {
  background: transparent;
  &:hover {
    background: #f1f1f1;
  }
  &:active {
    background: #e6e6e6;
  }
}

.fontColorWhite {
  color: #ffffff;
}

.fontColorBlack {
  color: #000000;
}

.fontColorLight {
  color: #6ac3fa;
}
