
  .root {
    display: block;
    position: relative;
    width: 100%;
  }

  .form {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .input {
    width: 100%;
    height: 45px;
    border-radius: 3px;
    border: none;
    font-size: 14px;
    color: #a9b1c2;
    padding-left: 12px;
    padding-right: 60px;
    box-sizing: border-box;
  }
  .input:focus::placeholder {
    color: transparent;
  }

  .submitButton {
    position: absolute;
    // top: 13px;
    top: 0;
    bottom: 0;
    // padding-top: 4px;
    right: 0;
    /* width: 30px;
    height: 30px; */
    border: none;
    outline: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    pointer-events: none;
    background-color: var(--bg-primary-color); // #14306a;
    margin-top: 8px;
    padding: 20px 8px 0 8px;
    border-radius: 4px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  }

  .notFoundText {
    color: white;
    display: block;
    margin: 0 auto 16px;
    text-align: center;
  }

  .resultsItem {
    // width: 274px;
    max-width: 274px;
    min-height: 20px;
    margin: 0 auto 10px;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--bg-secondary-color); // white;
    pointer-events: all;
    cursor: pointer;
    // по дизайну:
    // font-size: 14px;
    // font-family: var(--main-demibold-font);
    //
  }

  .resultsItem:hover {
    background-color: #dde2db;
  }

  .resultsItem:active {
    background-color: #cbd0ca;
  }

  // preloader, clear, info
  .actionsBlock {
    position: absolute;
    right: 34px;
    top: 12px;
  }
