
  .root {
    display: inline-block;
    width: 100%;
  }

  .text_input {
    border: 1px solid #e0e0e0;
    height: 40px;
    width: 100%;
    padding-left: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    box-sizing: border-box;
    border-radius: 2px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }
    &:focus::placeholder {
      color: transparent;
    }
  }

  .error_input {
    border-color: #ff4a4a;
  }

  .error_msg {
    color: #ff4a4a;
    margin: 0;
    padding: 2px 4px;
    line-height: 14px;
    font-size: 12px;
  }
